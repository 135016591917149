export const messages = [
    'Hello & Welcome ',
    `I am Eduard's assistant`,
    'Wanna make a tour?',
    'Here you can...',
    'Contact me',
    'Download my CV',
    'View My LinkedIn & GitHub',
    `Let's Continue`,
    'Here you can read about me',
    'I am very nice person',
    `It's my developing stack`,
    'I always happy to learn more',
    'Here you can see my projects',
    'Awesome projects,dont you think!?',
    'Here you can send email to me',
    'Share your thoughts with me',
    'Thank you , best regards!',
    'Restart? Click the button below'

]